import React from "react"
import styled from "@emotion/styled"
import mediaQuery from "styled-media-query"

const mediaMobile = mediaQuery.lessThan("medium")

const ContentListWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const ContentWrapper = styled("div")`
  border: solid 2px;
  border-color: #393e46;
  background-color: #ffffff;
  margin-bottom: 15px;
  &.col2 {
    margin-left: 10px;
  }
  ${mediaMobile`
    width: 100%;
    &.col2 {
      margin-left: 0px;
    }
  `}
`
const ContentHeader = styled("div")`
  width: 100%;
  margin: 0px;
  padding: 3px;
  background-color: #393e46;
  color: #ffffff;
`
const ContentInfo = styled("div")`
  margin: 3px 3px 0px;
  padding: 0px;
  overflow: auto;
  border-bottom: solid 3px;
  border-color: #00adb5;
`
const ContentDate = styled("span")`
  font-size: 75%;
  padding: 3px 3px 0px;
  display: inline-block;
  float: left;
`
const ContentCategory = styled("span")`
  font-size: 75%;
  padding: 3px 3px 0px;
  background-color: #00adb5;
  max-width: 80px;
  text-align: center;
  color: #393e46;
  right: 0px;
  float: right;
`
const ContentTitle = props => {
  const Wrapper = styled("div")`
    margin: 0px;
    ${ContentWrapper}.list & {
      height: 55px;
      display: flex;
      align-items: center;
    }
    & h1 {
      font-size: 1.35em;
      margin: 12px 6px;
    }
    & h2 {
      margin: 6px;
      font-size: 1.2em;
      ${ContentWrapper}.list & {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        white-space: normal;
      }
    }
  `
  const Title = styled(props.tag)``
  return (
    <Wrapper>
      <Title>{props.children}</Title>
    </Wrapper>
  )
}
const ContentBody = styled.div(props => {
  return `
    width: 100%;
    padding: 15px;
    ${ContentWrapper}.list & {
      display: table;
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 6px 0;
      width: 100%;
      height: 110px;
      padding: 0px;
    }
  `
})
const ContentText = props => {
  const Text = styled.div(props => {
    return `
      overflow-wrap: break-word;
      margin: 0px;
      padding: 0px;
      ${ContentWrapper}.list & {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        white-space: normal;
      }
      h2 {
        display: inline-block;
        border-bottom: 3px solid #00adb5;
      }
      a {
        font-weight: bolder;
      }
    `
  })
  return (
    <Text
      className={props.className}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
    >
      {props.children}
    </Text>
  )
}
const ContentImage = props => {
  const Wrapper = styled.div`
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 0px;
    width: 80px;
  `
  const Img = styled.img`
    vertical-align: middle;
    height: 80px;
    width: 80px;
    object-fit: cover;
    margin: 0px;
  `
  return (
    <Wrapper>
      <Img src={props.src} alt={props.alt}></Img>
    </Wrapper>
  )
}

export {
  ContentListWrapper,
  ContentWrapper,
  ContentHeader,
  ContentInfo,
  ContentDate,
  ContentCategory,
  ContentTitle,
  ContentBody,
  ContentImage,
  ContentText,
}
